import type { Variables } from 'graphql-request'
import { GraphQLClient } from 'graphql-request'

import { fetch } from '~/utils/fetch'

const endpoint =
  (typeof window !== 'undefined' && window.ENV?.GRAPHQL_URL) ||
  (typeof process !== 'undefined' && process.env.GRAPHQL_URL) ||
  ''

const client = new GraphQLClient(endpoint, {
  fetch,
  credentials: 'include',
  mode: 'cors'
})

export const backend = async <T>(
  document: string,
  variables?: Variables,
  request?: Request,
  headers: Record<string, string> = {},
  cookieHeader?: string | null
) => {
  // Don't send any backend requests, if in maintenance mode
  const isMaintenanceMode = Boolean(
    parseInt(
      (typeof window !== 'undefined' && window.ENV?.MAINTENANCE_MODE) ||
        (typeof process !== 'undefined' && process.env.MAINTENANCE_MODE) ||
        '0',
      10
    )
  )
  if (isMaintenanceMode) {
    return
  }

  // Extract out the `keystonejs-session` cookie value. We can use this to
  // authenticate the GraphQL API
  const cookies = request?.headers.get('cookie') || cookieHeader
  const cookieArray = cookies?.split('; ') || []
  let token = null

  for (let cookie of cookieArray) {
    if (cookie.startsWith('keystonejs-session=')) {
      token = cookie.split('=')[1]
      break
    }
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  console.log('\nGraphQL request', document, variables)
  console.log('Authorization: ', headers['Authorization'])

  const response = await client.rawRequest<T>(document, variables, headers)

  console.log('\nGraphQL response', response?.data)

  return response
}
