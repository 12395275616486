import { gql } from 'graphql-request'

import { backend } from '../utils/api'

const FILE_ASSET_DOWNLOAD = gql`
  mutation LogDownload($fileAssetId: String!, $printableId: String) {
    logDownload(fileAssetId: $fileAssetId, printableId: $printableId)
  }
`

interface LogDownloadProps {
  fileAssetId: string
  printableId?: string | null
}

const useDownloadLogger = () => {
  const logDownload = async ({ printableId, fileAssetId }: LogDownloadProps) => {
    return await backend(FILE_ASSET_DOWNLOAD, {
      fileAssetId: fileAssetId,
      printableId: printableId
    })
  }

  return { logDownload }
}

export default useDownloadLogger
